import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  CardVideo,
  MoreLink,
  SectionFull,
  ViewAll
} from "@website2018/da-dobsonville";
import Media from "react-media";
import YouTubeVideo from './YouTubeVideo';
import SettingsProvider from '../../containers/Settings/SettingsProvider';
import { decode } from "he";

const Videos = (props, context) => {
  if (!props.videos.length) return null;

  const {
    settings: {
      social: { youtube }
    }
  } = context;
  const one = props.videos[0] || null;
  const two = props.videos[1] || null;
  const three = props.videos[2] || null;

  return (
    <SectionFull
      bgColor="blueLight"
      color="white"
      title={props.title}
      mb="none"
      pb="large"
      pt="large"
    >
      <Media query="(min-width: 992px)">
        {matches =>
          matches ? (
            <Fragment>
              <div className="col-md-8">
                <YouTubeVideo
                  key={one.videoId}
                  videoId={one.videoId}
                  title={decode(one.title.rendered)}
                  render={() => (
                    <CardVideo
                      videoId={one.videoId}
                      featured
                      imageUrl={null}
                      title={decode(one.title.rendered)}
                      paragraph={one.excerpt.rendered}
                      cta="Play Video"
                      color="white"
                    />
                  )}
                />
              </div>
              <div className="col-md-4">
                {two && (
                  <YouTubeVideo
                    key={two.videoId}
                    videoId={two.videoId}
                    title={decode(two.title.rendered)}
                    render={() => (
                      <CardVideo
                        featured
                        imageUrl={null}
                        title={decode(two.title.rendered)}
                        color="white"
                      />
                    )}
                  />
                )}
                {three && (
                  <YouTubeVideo
                    key={three.videoId}
                    videoId={three.videoId}
                    title={decode(three.title.rendered)}
                    render={() => (
                      <CardVideo
                        featured
                        imageUrl={null}
                        title={decode(three.title.rendered)}
                        color="white"
                      />
                    )}
                  />
                )}
              </div>
            </Fragment>
          ) : (
            <div className="col-12" key={"mobile-video"}>
              <YouTubeVideo
                key={`mobile-${one.videoId}`}
                videoId={one.videoId}
                title={decode(one.title.rendered)}
                render={() => (
                  <CardVideo
                    videoId={one.videoId}
                    featured
                    imageUrl={null}
                    title={decode(one.title.rendered)}
                    paragraph={one.excerpt.rendered}
                    cta="Play Video"
                    color="white"
                  />
                )}
              />
            </div>
          )
        }
      </Media>

      <div className="col-md-12">
        <a href={youtube} target="_blank" rel="noopener noreferrer">
          <ViewAll
            borderColor="rgba(255,255,255,0.15)"
            buttonColor="white"
            href={youtube}
          >
            View all Videos
          </ViewAll>
        </a>
      </div>
    </SectionFull>
  );
};

Videos.contextTypes = {
  settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired
};

export default Videos;
